import React, {useState, useEffect} from "react"
import { useCookies } from 'react-cookie';
import Token from "./modules/Token";
import Auth from './modules/Auth';
import Connection from "./modules/Connection";
import './App.css';

function App() {

  const [step, setStep] = useState(1)
  const [token, setToken] = useState("")

  const [cookies, setCookie] = useCookies(["step", "token"]);

  useEffect(() => {
    if (cookies !== undefined) {
      if (cookies["step"] !== undefined) {
        setStep(parseInt(cookies["step"]))
      }
      if (cookies["token"] !== undefined) {
        setToken(cookies["token"])
      }
    }
  },[cookies])

  const updateStep = (new_step) => {
    setStep(new_step)
    let d = new Date();
    d.setTime(d.getTime() + (365*25*60*60*1000));
    setCookie("step", new_step, {path: "/", expires: d});
  }

  const updateToken = (new_token) => {
    setToken(new_token)
    let d = new Date();
    d.setTime(d.getTime() + (365*25*60*60*1000));
    setCookie("token", new_token, {path: "/", expires: d});
  }

  return (
    <div className="app">
      <header className="app-header">
        <h1>Feelings and colors</h1>
      </header>
      <div className="page">
        {
          step >= 1 ? <Token step={step} setStep={updateStep} token={token} setToken={updateToken}/> : <></>
        }
        {
          step >= 2 ? <Auth setStep={updateStep} token={token}/> : <></>
        }
        {
          step == 0 ? <Connection token={token}/> : <></>
        }
        
      </div>
    </div>
  );
}

export default App;
