import React, {useEffect, useState} from "react"
import "./Modules.css";

export default function Connection(props) {

  const api = process.env.REACT_APP_API_URL;
  const [user, setUser] = useState({"loading": true})

  useEffect(() => {
    if (props.token !== undefined) {
      fetch(api + "/user?token=" + props.token)
      .then((response) => response.json())
      .then((response) => {
        setUser(response)
      })
      .catch((error) => console.log(error))
    }
  }, [props.token])

  return (
    <div className="module-container">
      <h2 className="module-title">Estado</h2>
      {
        user.loading ? <></> :
        <div>
          <p className="module-text">Hola {user.name}. Tu cuenta de Spotify está conectada.</p>
        </div>
      }
    </div>
  )
}