import React, {useEffect} from "react"
import Button from '@mui/material/Button';
import "./Modules.css";

export default function Auth(props) {

  const api = process.env.REACT_APP_API_URL;
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const redirect = "https://feelingsandcolors.enriquegomez.me/"

  useEffect(() => {
    const code = getParameterByName("code")
    if (code.length > 0) {
      if (props.token !== undefined) {
        if (props.token.length > 0) {
          fetch(api + "/spotify/authenticate?token=" + props.token + "&code=" + code)
          .then((response) => response.json())
          .then((response) => {
            if (response["connected"])
              props.setStep(0)
          })
          .catch((error) => console.log(error))
        }
      }
    }
  },[props.token])

  const authUser = () => {
    var url = "https://accounts.spotify.com/authorize?client_id=" + client_id + "&response_type=code&redirect_uri=" + redirect + "&scope=user-read-playback-state"
    window.location.href = url
  }

  const getParameterByName = (name) => {
    name = name.replace(/[[]/, "[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

  return (
    <div className="module-container">
      <h2 className="module-title">Paso 2</h2>
      <p className="module-text">Autentícate en Spotify.</p>
      <Button
        variant="contained"
        onClick={() => authUser()}
      >
        Autentícar
      </Button>
    </div>
  )
}