import React from "react"
import Button from '@mui/material/Button';
import "./Modules.css";

export default function Token(props) {

  const api = process.env.REACT_APP_API_URL;

  const validateToken = () => {
    if (props.token.length > 0) {
      fetch(api + "/token/validate?token=" + props.token)
      .then((response) => response.json())
      .then((response) => {
        if (response["valid"])
          props.setStep(2)
      })
      .catch((error) => console.log(error))
    }
  }

  return (
    <div className="module-container">
      <h2 className="module-title">Paso 1</h2>
      <p className="module-text">Introduce tu código de invitación.</p>
      <div className="module-row">
        <input 
          type="text"
          className="token-input"
          value={props.token}
          onChange={(event) => {
            props.setToken(event.target.value)
          }}
        />
        <Button
          variant="contained"
          onClick={validateToken}
          disabled={props.step !== 1}
        >
          Verificar
        </Button>
      </div>
    </div>
  )
}